<template>
  <div class="login">

    <div class="left_side" :style="onload ? 'left: 70px; opacity: .7;' : null">

      <form @submit.prevent="signInClicked" class="form" v-if="!forgot_password">

      <h2>Log In</h2>
      <p>Living Knowledge v8.1</p>

        <p class="err_message" v-if="err_login || err_pass || err_forget" v-text="errMsg"></p>

        <!-- <div class="fields" :style="[forgot_password ? 'margin-left: -400px;' : null]"> -->
          <label>
            <input type="text" placeholder="Login" :class="{ 'err_login': err_login }" v-model="login" @keypress="err_login = false; err_pass = false">
          </label>

          <label>
            <input :type="state.fieldType" placeholder="Password" v-model="password" :class="{ 'err_pass': err_pass }"
              @keypress="err_login = false; err_pass = false">
            <span class="eye" @click="passToggle">
              <span v-if="state.showPass"></span>
            </span>
          </label>

          <div class="forgot" @click="forgot_password = true;login='';password='';err_login = false; err_pass = false;">Forgot password</div><br>

          <button class="submit_login" @click="signInClicked">LOG IN</button>
        <!-- </div> -->

      </form>


      <form @submit.prevent="signInClicked" class="form" v-if="forgot_password">

        <h2>Log In</h2>
        <p>Living Knowledge v8.1</p>
          <label>
            <input type="text" placeholder="Enter your email" :class="{ 'err_login': err_forget }" v-model="email" @keypress="err_forget = false; err_forget = false">
          </label>
          <div class="forgot" @click="forgot_password = false; err_forget = false; err_login = false; err_pass = false; email = ''">
            Go back to Login
          </div>
          <button class="submit_login" @click="sendEmailClicked">RESET PASSWORD</button>
      </form>


    </div>

    <div class="right_side" :style="onload ? 'right: 70px; opacity: .7;' : null">
      <div class="top"></div>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script>

import { reactive, ref } from "vue";
// import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';

export default {
  name: 'login',
  setup() {
    let state = reactive({
      showPass: false,
      fieldType: 'password',
    });

    const onload = ref(true);

    // let showPass = ref(false);
    // let fieldType = ref('password');

    const passToggle = () => {
      state.showPass = !state.showPass;
      // console.log(showPass,'showPass')
      state.fieldType = state.fieldType === 'password' ? 'text' : 'password';
    };

    let login = ref('');
    let password = ref('');
    let err_login = ref(false);
    let err_pass = ref(false);
    let err_forget = ref(false);
    let forgot_password = ref(false);
    let errMsg = ref('Wrong Login or Password');
    const signInClicked = async () => {
      try {
        let response = await EmailPassword.signIn({
          formFields: [{
            id: "email",
            value: login.value
          }, {
            id: "password",
            value: password.value
          }]
        });

        if (response.status === "FIELD_ERROR") {
          response.formFields.forEach(formField => {
        // console.log(formField.error,'formField.error');
        // console.log(response.status,'response.status');
            if (formField.id === "email") {
              // Email validation failed (for example incorrect email syntax).
              err_login.value = true;
              errMsg.value = formField.error
            }
            if (formField.id === "password") {
              // Email validation failed (for example incorrect email syntax).
              err_pass.value = true;
            }
          })
        } else if (response.status === "WRONG_CREDENTIALS_ERROR") {
          console.log(response.status,"Email password combination is incorrect.");
          errMsg.value = "Email password combination is incorrect."
          err_login.value = true;
          err_pass.value = true;
        } else {
          // sign in successful. The session tokens are automatically handled by
          // the frontend SDK.
          let user = response.user;
          fetch(`https://auth.wave7.cloud/auth/userinfo`, {
            method: 'POST',
          }).then((res) => res.json()).then((response) => {
            user.user_metadata = response.metadata;
            user.token = response.token;
            //user.role = 'admin'; //todo ADD roles and permissions. Temp admin role for all users
            user.role = user.user_metadata.role;
            localStorage.setItem("v8_user", JSON.stringify(user));
            if (user.user_metadata.exposedTreeNodes !== undefined) {
              localStorage.setItem("exposedTreeNodes", JSON.stringify(user.user_metadata.exposedTreeNodes));
            }
            window.location.href = "/";
          }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
          });
        }
      } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          window.alert(err.message);
        } else {
          window.alert("Oops! Something went wrong.");
        }
      }
    };

    let email = ref('');
    const sendEmailClicked = async () => {
      try {
        let response = await EmailPassword.sendPasswordResetEmail({
          formFields: [{
            id: "email",
            value: email.value
          }]
        });

        if (response.status === "FIELD_ERROR") {
          // one of the input formFields failed validation
          response.formFields.forEach(formField => {
            if (formField.id === "email") {
              // Email validation failed (for example incorrect email syntax).
              // console.log(formField.error);
              err_forget.value = true;
              errMsg.value = formField.error;
            }
          })
        } else {
          // reset password email sent.
          window.alert("Please check your email for the password reset link")
        }
      } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
          // this may be a custom error message sent from the API by you.
          window.alert(err.message);
        } else {
          window.alert("Oops! Something went wrong.");
        }
      }
    };

    return {
      err_login,
      err_pass,
      err_forget,
      onload,
      forgot_password,
      login,
      password,
      email,
      errMsg,
      passToggle,
      sendEmailClicked,
      signInClicked,
      state,
    };
  },
  created() {
    localStorage.removeItem('edit')
  },
  mounted() {
    this.onload = false;
  },
};
</script>

<style lang="scss" scoped>
.login {
  background-color: #f8f8f8;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .left_side, .right_side {
    transition: all 0.3s ease;
  }

  .left_side {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #f8f8f8;

    .form {
      overflow: hidden;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%);
      margin-top: -180px;
      // margin-left: -200px;
      width: 400px;
      text-align: center;

      .fields {
        width: 800px;
        max-width: calc(100% * 2);
        position: relative;
        text-align: left;
        transition: all .3s ease;
      }

      .forgot {
        font-size: 13px;
        text-align: right;
        cursor: pointer;
        color: #006C3E;
        margin: 8px 0;
        // width: 50%;
        max-width: 400px;
      }

      .err_login, .err_pass {
        border: 1px solid #CD180A;
      }

      .err_message {
        margin: 0;
        font-size: 13px;
        text-align: left;
        line-height: 16px;
        margin-top: -10px;
        margin-bottom: 10px;
        color: #CD180A;
        text-indent: 10px;
      }

      label {
        position: relative;
        // width: 50%;
        margin-top: 16px;
        display: block;
        left: 0;
      }

      .eye {
        display: inline-block;
        height: 40px;
        width: 40px;
        position: absolute;
        right: 5px;
        background-image: url(../../public/img/password.png);
        background-position: center;
        background-repeat: no-repeat;

        span {
          display: inline-block;
          height: 27px;
          width: 2px;
          background-color: #787878;
          transform: rotate(60deg);
          position: relative;
          top: 7px;
          right: 0px;
        }
      }

      > p {
        color: #787878;
        font-size: 17px;
        line-height: 24px;
        margin-bottom: 40px;
      }

      h2 {
        font-size: 32px;
        line-height: 40px;
        height: 40px;
        margin-bottom: 16px;
      }

      input, button {
        max-width: 400px;
        box-sizing: border-box;
        border: none;
        outline: none;
        border-radius: 8px;
        transition: all .3s;
      }

      button:hover {
        background-color: #f2f8f5;
        color: #006C3E;
        border: 1px solid #006C3E;
      }

      input {
        width: 100%;
        border: 1px solid #fff;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.02);
        text-indent: 16px;
        color: #787878;
        font-size: 17px;
        position: relative;
      }

      input::placeholder {
        color: #787878;
        font-size: 17px;
        font-family: 'Inter', sans-serif;
      }

      button {
        width: 100%;
        height: 48px;
        background-color: #006C3E;
        font-weight: 500;
        font-size: 17px;
        color: #fff;
      }
    }
  }

  .right_side {
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    background-color: #006C3E;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../public/img/w7login.png);

    .top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 40%;
      transform: rotate(180deg);
    }

    .bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40%;
    }

    .top, .bottom {
      background-image: url(../../public/img/vectors.png);
      background-position: bottom;
      background-repeat: repeat-x;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px) and (min-height: 961px) and (orientation: portrait) {
  .login {
    .left_side {
      background-image: url(../../public/img/vectors2.png);
      background-position: top;
      background-repeat: repeat-x;
      width: 100%;

      .form {
        background-image: url(../../public/img/w7_green.svg);
        background-size: 58px;
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 50px;
        top: 50%;
      }
    }

    .right_side {
      display: none;
    }
  }
}

@media screen and (max-width: 599px) and (orientation: portrait) {
  .login {
    .left_side {
      background-image: url(../../public/img/vectors2.png);
      background-position: center -60px;
      background-repeat: repeat-x;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;

      .form {
        background-image: url(../../public/img/w7_green.svg);
        background-size: 44px;
        background-position: center top;
        background-repeat: no-repeat;
        padding-top: 50px;
        width: inherit;
        box-sizing: border-box;
        position: relative;
        margin-top: 230px;
        text-align: center;
        top: auto;
        left: auto;
        margin-left: auto;
        transform: translateX(0%);
        > p {
          color: #787878;
          font-size: 17px;
          line-height: 24px;
          margin-bottom: 16px;
          margin-top: 8px;
        }

        h2 {
          font-size: 26px;
          line-height: 32px;
          height: 32px;
          margin-bottom: 8px;
        }
      }
    }

    .right_side {
      display: none;
    }
  }
}

@media screen and (max-height: 500px) and (orientation: landscape) {
  .login {
    .left_side {
      width: 100%;
    }

    .right_side {
      display: none;
    }
  }
}

.slide-err-enter-active,
.slide-err-leave-active {
  transition: all 0.3s ease-out;
}

.slide-err-enter-from,
.slide-err-leave-to {
  margin-top: -30px;
}
</style>